<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">从考务处选择</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-end">
          <div class="searchbox">
            <el-button type="primary" class="bgc-bv" round @click="examinationSelect">使用考务</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe @selection-change="handleSelectionChange"
              :row-key="(row) => row.activityExamId">
              <el-table-column type="selection" :reserve-selection="true" width="55" align="left" />
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="考务名称" align="left" show-overflow-tooltip prop="activityName" minWidth="200" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" minWidth="200" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="levelCode" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试科目" align="left" prop="subjectCode" show-overflow-tooltip min-width="150">
                <template slot-scope="scope">
                  {{ $setDictionary("EA_EXAM_SUBJECT_CODE", scope.row.subjectCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试时间" align="left" show-overflow-tooltip width="300px">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="考试人数" align="left" show-overflow-tooltip prop="peopleNum" minWidth="100" />
              <el-table-column label="考场状态" align="left" prop="state" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("EA_ACTIVITY_STATE", scope.row.state) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="usingOrganization(scope.row)">使用考务</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_chooseFromOrganization",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 列表已勾选数据
      multipleSelection: [],
    };
  },
  created() { },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      this.doFetch({
        url: "/biz/examination/selectActivityItem",
        params,
        pageNum,
      }, true, 6);
      setTimeout(()=>{
        console.log(this.tableData)
      },1000)
    },
    // 使用考务(单条)
    usingOrganization(row) {
      this.$confirm('确认使用该考务吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post("/biz/examination/addFromActivity", { activityExamId: row.activityExamId }, 3000, true, 6)
          .then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.$router.back();
          })
          .catch((err) => {
            return;
          });
      }).catch((err) => {
        return;
      });
    },
    // 列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 使用考务(批量)
    examinationSelect() {
      if(this.multipleSelection.length>0){
        let activityExamIds = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          activityExamIds.push(this.multipleSelection[i].activityExamId)
        }
        this.$post("/biz/examination/addFromActivityBatch", { activityExamIds: activityExamIds }, 3000, true, 6)
          .then((res) => {
            this.$refs.multipleTable.clearSelection();
            this.$message.success('操作成功')
            this.$router.back();
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$message({
          message: '请选择考务',
          type: 'warning'
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
  